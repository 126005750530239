export var SignalWallId;
(function (SignalWallId) {
    SignalWallId["\u76E4\u4E2D\u7A81\u7834\u65E5\u5167\u9AD8\u9EDE"] = "RT_futuresai_01";
    SignalWallId["\u76E4\u4E2D\u7A81\u7834\u65E5\u5167\u4F4E\u9EDE"] = "RT_futuresai_02";
    SignalWallId["\u76E4\u4E2D\u9AD8\u9810\u4F30\u5468\u8F49\u7387"] = "RT_futuresai_03";
    SignalWallId["\u76E4\u4E2D\u9AD8\u9810\u4F30\u91CF\u6BD4"] = "RT_futuresai_04";
    SignalWallId["\u76E4\u4E2D\u5275\u4E94\u65E5\u65B0\u9AD8"] = "RT_futuresai_05";
    SignalWallId["\u76E4\u4E2D\u5275\u4E94\u65E5\u65B0\u4F4E"] = "RT_futuresai_06";
    SignalWallId["\u591A\u65B9\u9078\u80A1"] = "ED_m168168_01";
    SignalWallId["\u7A7A\u65B9\u9078\u80A1"] = "ED_m168168_02";
    SignalWallId["\u77ED\u7DDA\u8ECB\u7A7A"] = "ED_m168168_03";
    SignalWallId["\u64BF\u4FBF\u5B9C"] = "ED_m168168_04";
    SignalWallId["MVP\u591A\u65B9"] = "RT_mvp888_stock_01";
    SignalWallId["MVP\u7A7A\u65B9"] = "RT_mvp888_stock_02";
    SignalWallId["\u5927\u55AE\u6210\u4EA4"] = "qty_alert";
    SignalWallId["\u5927\u55AE\u6210\u4EA4\u65B0\u7248"] = "qty_alert2";
    SignalWallId["\u5C55\u54E5\u63DB\u624B\u7387\u6392\u884C"] = "RT_wu5868_stock_01";
    SignalWallId["\u5C55\u54E5\u7A81\u78345\u65E5\u9AD8"] = "RT_wu5868_stock_02";
    SignalWallId["\u5C55\u54E5\u62C9\u56DE\u8CB7\u9032"] = "RT_wu5868_stock_03";
    // low_price_qty_alert
    // middle_price_qty_alert
    // high_price_qty_alert
    // super_high_price_qty_alert
    /** South */
    SignalWallId["SOUTH_1"] = "RT_south_01";
    SignalWallId["SOUTH_2"] = "RT_south_02";
    SignalWallId["SOUTH_3"] = "RT_south_03";
    SignalWallId["SOUTH_4"] = "RT_south_04";
    SignalWallId["SOUTH_5"] = "RT_south_05";
    SignalWallId["SOUTH_6"] = "RT_south_06";
    SignalWallId["SOUTH_7"] = "RT_south_07";
    SignalWallId["SOUTH_8"] = "RT_south_08";
    SignalWallId["SOUTH_9"] = "RT_south_09";
    SignalWallId["SOUTH_10"] = "RT_south_10";
    SignalWallId["SOUTH_11"] = "RT_south_11";
    /** Gorich55688_stock */
    SignalWallId["gorich55688_\u5F37\u52E2\u6574\u7406\u80A1"] = "ED_gorich55688_01";
    SignalWallId["gorich55688_\u7C4C\u78BC\u512A\u52E2\u80A1"] = "ED_gorich55688_02";
    // 更生人選股
    SignalWallId["RV01"] = "RV01";
    SignalWallId["RV02"] = "RV02";
    SignalWallId["RV03"] = "RV03";
})(SignalWallId || (SignalWallId = {}));
