import { css } from '@emotion/react';
import { DatePickerInput } from '@mantine/dates';
import { every, filter, find, get, isDate, map, sortBy } from 'lodash';
import { Fragment, useCallback } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { proxy, ref, subscribe, useSnapshot } from 'valtio';
import { apirc } from '~/configs/apirc';
import SymbolCandleStick from '~/modules/SDK/Symbol/SymbolCandleStick';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { component } from '~/utils/component';
import dayAPI from '~/utils/dayAPI';
import { delay } from '~/utils/delay';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
export class FuiSignals {
    /** 時間近的靠前 */
    static sorterOfTimeNear = function (datum) {
        return -dayAPI(datum.datetime).unix();
    };
    /** 時間遠的靠前 */
    static sorterOfTimeFar = function (datum) {
        return dayAPI(datum.datetime).unix();
    };
    /** 收盤價大的的靠前 */
    static sorterOfCloseUpper = function (datum) {
        if (datum.signal_data && 'close' in datum.signal_data) {
            return -datum.signal_data.close;
        }
        return 0;
    };
    /** 收盤價小的的靠前 */
    static sorterOfCloseLower = function (datum) {
        if (datum.signal_data && 'close' in datum.signal_data) {
            return datum.signal_data.close;
        }
        return 0;
    };
    static sorterBySymbolOfNear = function (datum) {
        return -Number(datum.symbol);
    };
    static sorterBySymbolOfFar = function (datum) {
        return Number(datum.symbol);
    };
    static getPayloadValue(payload, key, defaultsValue) {
        return get(payload, key, defaultsValue);
    }
    store;
    /**
     * - 從 remote 抓回來的原生值
     * - 未經過 sorter 或 filter 處理過的
     */
    dataOfRaw = [];
    /** 文字篩選 */
    filterOfText = datum => {
        const text = this.store.filter.text;
        return (datum?.message?.includes(text) ||
            datum?.symbol?.includes(text) ||
            useSymbolDictionaryStore.getState().dictionary[datum.symbol].includes(text));
    };
    /** 成交(元)是 `volume * close` */
    filterOfAmount = datum => {
        const min = this.store.filter?.minAmount ?? 0;
        const max = this.store.filter?.maxAmount ?? 0;
        const volume = FuiSignals.getPayloadValue(datum.signal_data, 'volume', 0);
        const close = FuiSignals.getPayloadValue(datum.signal_data, 'close', 0);
        const value = volume * close;
        return every([min <= value, value <= max]);
    };
    /** 成交(張) */
    filterOfVolume = datum => {
        const min = this.store.filter?.minVolume ?? 0;
        const max = this.store.filter?.maxVolume ?? 0;
        const value = FuiSignals.getPayloadValue(datum.signal_data, 'volume', 0);
        return every([min <= value, value <= max]);
    };
    /** 成交(張) */
    filterOfStockFuture = datum => {
        return fr_instrument.getSymbol(datum.symbol).hasFutures;
    };
    constructor(options) {
        this.store = proxy({
            ...options,
            data: [],
            configs: options.configs,
            from: options.from || dayAPI(),
            to: options.to || options.from?.add(1, 'day') || dayAPI().add(1, 'day'),
            filter: {
                text: '',
                minAmount: 0,
                maxAmount: 1e11,
                minVolume: 0,
                maxVolume: 1e11,
                stockFuture: false,
                ...options.filter,
            },
            sorter: options.sorter ||
                FuiSignals.sorterOfTimeNear,
        });
        // 當 options.from 未指定時，那麼我們將 latest trade date 作為預設值
        // 使 UI 在連續假日時，也看起來不會空白一片
        const unsub = subscribe(staticStore.tradedDate, ops => {
            this.store.from = staticStore.tradedDate.intraday;
            this.store.to = staticStore.tradedDate.intraday.add(1, 'day');
            delay(1000).then(unsub);
        });
    }
    /** 你給兩個 sorter 讓此函式處理在這兩個之間作變換 */
    toggleInSorters(sorters) {
        if (this.store.sorter !== sorters[0]) {
            this.store.sorter = sorters[0];
        }
        else if (this.store.sorter !== sorters[1]) {
            this.store.sorter = sorters[1];
        }
    }
    toggle(config, toEnable) {
        const found = find(this.store.configs, item => item.query === config.query);
        if (!found) {
            console.warn(`找不到相應組態`, {
                你傳入: config,
                已有的: this.store.configs,
            });
            return false;
        }
        found.enabled = toEnable ?? !found.enabled;
        return found.enabled;
    }
    toSymbols() {
        return map(this.store.data, datum => datum.symbol);
    }
    /** 將現有 data 套用現有 sorters 並更新到 store 裡去 */
    update() {
        let data = this.dataOfRaw;
        data = filter(data, this.filterOfAmount);
        data = filter(data, this.filterOfVolume);
        data = filter(data, this.filterOfText);
        if (this.store.filter.stockFuture)
            data = filter(data, this.filterOfStockFuture);
        if (this.store.sorter) {
            data = sortBy(data, this.store.sorter);
        }
        return (this.store.data = data);
    }
    async fetchAndUpdate() {
        let items = [];
        for await (const signal of this.store.configs) {
            if (!signal.enabled) {
                continue;
            }
            const _items = await apirc.signalWall.data.fetch({
                signal_id: signal.query,
                from: this.store.from.set('hours', 8).set('minutes', 0).set('seconds', 0),
                to: this.store.to.set('hours', 23).set('minutes', 59).set('seconds', 59),
                size: 200,
                page: 1,
            });
            items = [...items, ..._items];
        }
        this.dataOfRaw = items;
        this.update();
        return this.store.data;
    }
    WithFilter = ref(component(props => {
        const state = useSnapshot(this.store.filter);
        return <Fragment>{props.children(state)}</Fragment>;
    }));
    WithSortButton = ref(component(props => {
        const sortThat = useCallback(() => {
            if (props.variant === 'time') {
                return this.toggleInSorters([FuiSignals.sorterOfTimeNear, FuiSignals.sorterOfTimeFar]);
            }
            if (props.variant === 'close') {
                return this.toggleInSorters([
                    FuiSignals.sorterOfCloseUpper,
                    FuiSignals.sorterOfCloseLower,
                ]);
            }
            if (props.variant === 'symbol') {
                return this.toggleInSorters([
                    FuiSignals.sorterBySymbolOfNear,
                    FuiSignals.sorterBySymbolOfFar,
                ]);
            }
            return;
        }, [props.variant]);
        return (<div css={css `
            display: contents;
            cursor: pointer;
            user-select: none;
          `} onClick={() => {
                sortThat();
            }}>
          {props.children}
        </div>);
    }));
    SorterIcon = ref(component(props => {
        const state = useSnapshot(this.store);
        if (state.sorter === props.lowerArrow) {
            return (<FiArrowDown css={css `
              transform: translateY(3px);
            `} className={props.className}/>);
        }
        if (state.sorter === props.upperArrow) {
            return (<FiArrowUp css={css `
              transform: translateY(3px);
            `} className={props.className}/>);
        }
        return null;
    }));
    DatePicker = ref(component(props => {
        const state = useSnapshot(this.store);
        return (<DatePickerInput className={props.className} locale='zh-tw' closeOnChange valueFormat='YYYY/MM/DD' firstDayOfWeek={0} value={state.from.toDate()} {...props.DatePickerProps} clearable={false} onChange={value => {
                if (isDate(value)) {
                    this.store.from = dayAPI(value).set('hour', 8).set('minute', 0).set('second', 0);
                    this.store.to = dayAPI(value).set('hour', 23).set('minute', 59).set('second', 59);
                    props.DatePickerProps?.onChange?.(value);
                }
            }} css={css `
            width: 100%;
          `}/>);
    }));
    /** - Props.data 會內部判別是否為 ohlc */
    ClosePrice = ref(component(props => {
        if (props.data && 'close' in props.data) {
            return (<div className={props.className} data-info='收盤價'>
              {props.data.close}
            </div>);
        }
        return (<div className={props.className} data-warn='沒有OHLC值無法渲染收盤價格'/>);
    }, {
        displayName: '收盤價',
    }));
    /** - Props.data 會內部判別是否為 ohlc */
    Candle = ref(component(props => {
        if (props.data && 'close' in props.data) {
            return (<div className={props.className} data-info='繪製了K棒'>
              <SymbolCandleStick width={24} height={40} strokeWidth={1} data={{
                    close: props.data.close || 0,
                    high: props.data.high || 0,
                    low: props.data.low || 0,
                    open: props.data.open || 0,
                    prevRef: props.data.prev_ref || 0,
                }}/>
            </div>);
        }
        return (<div className={props.className} data-warn='沒有OHLC值無法繪製K棒'/>);
    }, {
        displayName: 'K棒',
    }));
}
